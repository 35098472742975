import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { green, orange } from '@material-ui/core/colors';
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import rainVideo from './assets/rain.mp4'
import audio1 from './assets/Armoury Door v1.mp3'
import audio2 from './assets/Lookout Tower v1.mp3'
import img1 from './assets/20201104_131127.jpg'


const theme = createMuiTheme({
  palette: {
    secondary: {
      main: orange[ 500 ],
    },
  },
});

const a1 = {
  type: "audio",
  sources: [
    {
      src: audio1,
    }
  ],
  options: {

  }
};

const a2 = {
  type: "audio",
  sources: [
    {
      src: audio2,
    }
  ],
  options: {

  }
};

const videoSrc = {
  type: "video",
  sources: [
    {
      src: "https://vimeo.com/153749393",
      provider: "vimeo",
    }
  ]
}

function App () {
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        align="center"
        justify="center"
        spacing={0}>
        
        <Grid item container justify="center" align="center" xs style={{ height: '100%', background: '#000' }}>
          <img src={img1} id="myImg"/>
          <Grid item xs={8} style={{ alignSelf: 'center' }}>
            <MyComponent audioSrc={a1} />
          </Grid>
        </Grid>
     
        <Grid item container justify="center" align="center" xs style={{ height: '100%', background: '#000', position: 'relative' }}>
          <Grid item xs style={{ alignSelf: 'center' }}>
              <MyVideoComponent />
            
              <Grid item xs={8} style={{ width: '100%' }}>
                <MyComponent audioSrc={a2} />
              </Grid>
          
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default App;

export const MyComponent = ({audioSrc}) => {
  return (
    <>
      <Plyr source={audioSrc} options={
        {
          // iconUrl: "./assets",
        }
      } />
    </>
  );
};

export const MyVideoComponent = () => {
  return (
    <>
      <video autoPlay muted loop id="myVideo">
        <source src={rainVideo} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
    </>
  );
};